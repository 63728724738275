- section: Power Sigils
  items:

    - id: force
      text: Superior Sigil of Force
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, add]
      gw2id: 24615

    - id: accuracy
      text: Superior Sigil of Accuracy
      modifiers:
        attributes:
          Critical Chance: 7%
      gw2id: 24618
      temporaryBuff: false

    - id: impact/night/slaying-both
      text: Superior Sigil of Impact/Night/Slaying
      textOverride: Sigil of Impact/Night/Slaying
      subText: (both 7%/3%)
      modifiers:
        damage:
          Outgoing Strike Damage: [3%, add, 7%, mult]
      gw2id: 24868

    - id: impact/night/slaying-only-3
      text: Superior Sigil of Impact/Night/Slaying
      textOverride: Sigil of Impact/Night/Slaying
      subText: (only 3%)
      modifiers:
        damage:
          Outgoing Strike Damage: [3%, add]
      gw2id: 24868

    - id: air
      text: Superior Sigil of Air
      subText: '(single target; may be inaccurate)'
      amountData:
        label: '/10s'
        default: 3.1
        quantityEntered: 10
      modifiers:
        attributes:
          NonCrit Power Coefficient: 759.55
      gw2id: 24554
      temporaryBuff: true

    - id: fire-sigil
      text: Superior Sigil of Fire
      amountData:
        label: '/10s'
        default: 1.9
        quantityEntered: 10
      modifiers:
        attributes:
          NonCrit Power Coefficient: 586.925
      gw2id: 24548
      temporaryBuff: true

    - id: severance-perma
      text: Superior Sigil of Severance
      subText: (100% uptime)
      modifiers:
        attributes:
          Precision: [250, buff] # tested unconverted by Snappy Joe
          Ferocity: [250, buff] # tested unconverted by Snappy Joe
      gw2id: 84505
      temporaryBuff: true

    # 1.0 coefficient, 690.5 strength
    - id: hydromancy
      text: Superior Sigil of Hydromancy
      subText: (without on-hit/on-crit effects)
      amountData:
        label: '/10s'
        default: 1
        quantityEntered: 10
      modifiers:
        attributes:
          Power Coefficient: 690.5
      gw2id: 24597
      temporaryBuff: true

    - id: hydromancy-bonus
      text: Superior Sigil of Hydromancy
      subText: (+12.5% for "Sic 'Em!")
      amountData:
        label: '/10s'
        default: 1
        quantityEntered: 10
      modifiers:
        attributes:
          Power Coefficient: 776.8
      gw2id: 24597
      temporaryBuff: true

    - id: blood-sigil
      text: Superior Sigil of Blood
      amountData:
        label: '/10s'
        default: 1.9
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 451
          Siphon Coefficient: 0.075
      gw2id: 24570
      temporaryBuff: true

    - id: draining-sigil
      text: Superior Sigil of Draining
      amountData:
        label: '/10s'
        default: 0
        defaultInput: ???
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 1042
          Siphon Coefficient: 0.075
      gw2id: 70825
      temporaryBuff: true

    - id: leeching-sigil
      text: Superior Sigil of Leeching
      amountData:
        label: '/10s'
        default: 0
        defaultInput: ???
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 974
      gw2id: 24599
      temporaryBuff: true

- section: Support Sigils
  items:

    - id: concentration
      text: Superior Sigil of Concentration
      modifiers:
        attributes:
          Boon Duration: 10%
      gw2id: 72339
      temporaryBuff: false

- section: Heal Sigils
  items:

    - id: transference
      text: Superior Sigil of Transference
      modifiers:
        attributes:
          Outgoing Healing: 10%
      gw2id: 74326
      temporaryBuff: false

- section: Condition Duration Sigils
  items:

    - id: malice
      text: Superior Sigil of Malice
      modifiers:
        attributes:
          Condition Duration: 10%
      gw2id: 44950
      temporaryBuff: false

    - id: bursting
      text: Superior Sigil of Bursting
      modifiers:
        damage:
          Outgoing Condition Damage: [5%, add]
      gw2id: 44944

    - id: agony
      text: Superior Sigil of Agony
      modifiers:
        attributes:
          Bleeding Duration: 20%
      gw2id: 24612
      temporaryBuff: false

    - id: smoldering
      text: Superior Sigil of Smoldering
      modifiers:
        attributes:
          Burning Duration: 20%
      gw2id: 24624
      temporaryBuff: false

    - id: venom
      text: Superior Sigil of Venom
      modifiers:
        attributes:
          Poison Duration: 20%
      gw2id: 24632
      temporaryBuff: false

    - id: demons
      text: Superior Sigil of Demons
      modifiers:
        attributes:
          Torment Duration: 20%
      gw2id: 24583
      temporaryBuff: false

- section: Condition Effect Sigils
  items:

    # 3 stacks * 8 seconds
    # 0.25 coefficient, 690.5 strength
    - id: geomancy
      text: Superior Sigil of Geomancy
      subText: (without on-hit/on-crit effects)
      amountData:
        label: '/10s'
        default: 1
        quantityEntered: 10
      modifiers:
        attributes:
          Power Coefficient: 172.625
          Bleeding Coefficient: 24
      gw2id: 24605
      temporaryBuff: true

    # 1 stack * 6 seconds
    # 2s icd
    - id: earth
      text: Superior Sigil of Earth
      amountData:
        label: '/10s'
        default: 4.3
        quantityEntered: 10
      modifiers:
        attributes:
          Bleeding Coefficient: 6
      gw2id: 24560
      temporaryBuff: true

    # 3 stacks * 8 seconds
    - id: doom
      text: Superior Sigil of Doom
      amountData:
        label: '/10s'
        default: 1
        quantityEntered: 10
      modifiers:
        attributes:
          Poison Coefficient: 24
      gw2id: 24609
      temporaryBuff: true

    # 2 stacks * 5 seconds
    # 5s icd
    - id: torment
      text: Superior Sigil of Torment
      amountData:
        label: '/10s'
        default: 1.9
        quantityEntered: 10
      modifiers:
        attributes:
          Torment Coefficient: 10
      gw2id: 48911
      temporaryBuff: true

    # 0.15 coefficient * (probably) 690.5 weapon strength
    # Ineptitide: 2 stacks * 5 seconds
    - id: mischief-ineptitude
      text: Superior Sigil of Mischief
      subText: (with Ineptitide; 1 snowball)
      amountData:
        label: '/10s'
        default: 1
        quantityEntered: 10
      modifiers:
        attributes:
          Confusion Coefficient: 10
          Power Coefficient: 103.58
      gw2id: 68436
      temporaryBuff: true

- section: Temporary hard-coded dual sigils
  items:

    # geo power: 0.25 coeff, 690.5 strength, 6 procs/100 sec -> 10.4 power coefficient
    # geo bleed: 24 seconds, 6 procs/100 sec -> 1.44 avg bleed
    # torment: 10 seconds, 10 procs/106 sec -> 0.94 avg torment, using 1.22 based on log
    #
    # f1 passives from procs are 2.4s because of amplified wrath; every 3 hits if virtues
    # geo f1 passive: 1/3 * 2.4 * 6 procs/100 sec = 0.048 avg burning
    - id: geomancy-torment-cfb5-virtues
      text: 'Geo + Torment CFB 5 page RF'
      textOverride: 'Geo + Torment CFB 5 page RF'
      modifiers:
        attributes:
          Power Coefficient: 6.5
          Bleeding Coefficient: 1.44
          Torment Coefficient: 1.22
          Burning Coefficient: 0.048
      gw2id: 24605
      priceIds: []
      temporaryBuff: true

    # geo power: 0.25 coeff, 690.5 strength, every 14.5 sec -> 11.9 power coefficient
    # geo bleed: 24 seconds, every 14.5 sec -> 1.65 avg bleed
    # torment: 10 seconds, 14 procs/106 sec -> 1.32 avg torment, using 1.28
    #
    # f1 passives from procs are 2.4s because of amplified wrath; every 3 hits if virtues
    # geo f1 passive: 1/3 * 2.4 / 14.5 sec = 0.055 avg burning
    - id: geomancy-torment-cfb8-virtues
      text: 'Geo + Torment CFB 8 page'
      textOverride: 'Geo + Torment CFB 8 page'
      modifiers:
        attributes:
          Power Coefficient: 11.9
          Bleeding Coefficient: 1.65
          Torment Coefficient: 1.28
          Burning Coefficient: 0.055
      gw2id: 24605
      priceIds: []
      temporaryBuff: true

    # geo power: 0.25 coeff, 690.5 strength, every 20 sec -> 8.6 power coefficient
    # geo bleed: 24 seconds, every 20 sec -> 1.2 avg bleed
    #   minus 0.07 determined by getting coef based on log to be 0
    # torment: 10 seconds, every 8.5 sec -> 1.18 avg torment
    #   plus 0.03 determined by getting coef based on log to be 0
    #
    # f1 passives from procs are 2.4s because of amplified wrath; every 3 hits if virtues
    # geo f1 passive: 1/3 * 2.4 / 20 sec = 0.04 avg burning
    - id: geomancy-torment-cwb
      text: 'Geo + Torment CWB'
      textOverride: 'Geo + Torment CWB'
      modifiers:
        attributes:
          Power Coefficient: 8.6
          Bleeding Coefficient: 1.13
          Torment Coefficient: 1.21
          Burning Coefficient: 0.04
      gw2id: 24605
      priceIds: []
      temporaryBuff: true

    # air noncrit: 759.55 * procs per second which is 0.1885 from log
    # earth: at full would be 6 seconds, every 2.3ish sec -> 2.58 avg bleed
    # so roughly 1.29 but +0.14 due to weapon swap meaning we get a free proc every 10s
    #
    # f1 passives from procs are 2.4s because of amplified wrath; every 3 hits if virtues
    # air f1 passive: 1/3 * 2.4 * 0.1885 = 0.1508 avg burning
    - id: air-earth-cwb
      text: 'Air + Earth CWB'
      textOverride: 'Air + Earth CWB'
      modifiers:
        attributes:
          NonCrit Power Coefficient: 143.181
          Bleeding Coefficient: 1.43
          Burning Coefficient: 0.1508
      gw2id: 24554
      priceIds: []
      temporaryBuff: true

    # air noncrit: 759.55 * procs per second which is 0.19075 from log
    # torment: 10 seconds, every 8.5 sec -> 1.18 avg torment
    #   plus 0.02 determined by getting coef based on log to be 0
    #
    # f1 passives from procs are 2.4s because of amplified wrath; every 3 hits if virtues
    # air f1 passive: 1/3 * 2.4 * 0.19075 = 0.1526 avg burning
    - id: air-torment-cwb
      text: 'Air + Torment CWB'
      textOverride: 'Air + Torment CWB'
      modifiers:
        attributes:
          NonCrit Power Coefficient: 144.88
          Torment Coefficient: 1.2
          Burning Coefficient: 0.1526
      gw2id: 24554
      priceIds: []
      temporaryBuff: true

    # geo power: 0.25 coeff, 690.5 strength, every 27 sec -> 6.4 power coefficient
    # geo bleed: 24 seconds, every 27 sec -> 0.89 avg bleed
    # torment: 10 seconds, every 9.8 sec -> 1.02 avg torment
    #
    # f1 passives from procs are 2.4s because of amplified wrath; every 3 hits if virtues
    # geo f1 passive: 1/3 * 2.4 / 27 sec = 0.030 avg burning
    - id: geomancy-torment-cwb-alac
      text: 'Geo + Torment CWB Alac'
      textOverride: 'Geo + Torment CWB Alac'
      modifiers:
        attributes:
          Power Coefficient: 6.4
          Bleeding Coefficient: 0.89
          Torment Coefficient: 1.02
          Burning Coefficient: 0.03
      gw2id: 24605
      priceIds: []
      temporaryBuff: true

    # 4 geo in 97 sec
    # 12 torment in 97 sec; thanks jezereal for calculation
    #
    # geo power: 0.25 coeff, 690.5 strength, every 24.3 sec -> 7.1 power coefficient
    # geo bleed: 24 seconds, every 24.3 sec -> 0.99 avg bleed
    # torment: 10 seconds, every 8.1 sec -> 1.23 avg torment
    #
    # geo bleed on crit with barbed precision: 1/3 chance of 3s every 24.3 s, if critical
    - id: geomancy-torment-harb
      text: 'Geo + Torment Harbinger'
      textOverride: 'Geo + Torment Harbinger'
      subText: (with Barbed Precision)
      modifiers:
        attributes:
          Power Coefficient: 7.5
          Bleeding Coefficient: 1.04
          Torment Coefficient: 1.2
          Burning Coefficient: 0.035
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 4.1%}
      gw2id: 24605
      priceIds: []
      temporaryBuff: true

    # attempt at creating something similar to the above geo-torment setup
    # earth is 6 seconds every 2 and a bit seconds
    # torment is 10 seconds every 5 and a bit seconds
    # time on scepter (earth) is ~15.6 -> 6.7 procs
    # time on pistol (torment) is ~25.3
    # 6.7 gets to be rounded up since the sigil will be off cooldown at the
    # start of the time on scepter, 7 procs / 40.9 seconds = 1.03
    # similar math, torment then gets 6 procs / 40.9 seconds = 1.47
    - id: earth-torment-harb
      text: 'Earth + Torment Harbinger'
      textOverride: 'Earth + Torment Harbinger'
      modifiers:
        attributes:
          Bleeding Coefficient: 1.03
          Torment Coefficient: 1.47
      gw2id: 24560
      priceIds: []
      temporaryBuff: true

    # geo and doom are vaguely procced at the same frequency with all the
    # shroud usage (and specter has no fancy traits) so this implementation
    # just mashes the two together
    - id: geomancy-doom-alac-specter
      text: 'Geo + Doom Alac Specter'
      textOverride: 'Geo + Doom Alac Specter'
      amountData:
        label: '/10s'
        default: 1
        quantityEntered: 10
      modifiers:
        attributes:
          # Geo sigil
          Power Coefficient: 172.625
          Bleeding Coefficient: 24
          # Doom sigil
          Poison Coefficient: 24
      gw2id: 24605
      priceIds: []
      temporaryBuff: true


- section: Stacking Sigils
  items:

    - id: bloodlust
      text: Superior Sigil of Bloodlust
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Power: [250, converted]
      gw2id: 24575
      temporaryBuff: true

    - id: cruelty
      text: Superior Sigil of Cruelty
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Ferocity: [250, converted]
      gw2id: 67341
      temporaryBuff: true

    - id: perception
      text: Superior Sigil of Perception
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
        disableBlacklist: true
      modifiers:
        attributes:
          Precision: [250, converted]
      gw2id: 24580
      temporaryBuff: true

    - id: corruption
      text: Superior Sigil of Corruption
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Condition Damage: [250, converted]
      gw2id: 24578
      temporaryBuff: true

    - id: life
      text: Superior Sigil of Life
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Healing Power: [250, converted]
      gw2id: 24582
      temporaryBuff: true

    - id: bounty
      text: Superior Sigil of Bounty
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
        disableBlacklist: true
      modifiers:
        attributes:
          Concentration: [225, converted]
      gw2id: 81045
      temporaryBuff: true

    - id: benevolence
      text: Superior Sigil of Benevolence
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Outgoing Healing: 12.5%
      gw2id: 24584
      temporaryBuff: true

    - id: momentum
      text: Superior Sigil of Momentum
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
        disableBlacklist: true
      modifiers:
        attributes:
          Toughness: [125, converted]
      gw2id: 49457
      temporaryBuff: true

    - id: stars
      text: Superior Sigil of the Stars
      amountData:
        label: 'stacks'
        default: 25
        quantityEntered: 25
        disableBlacklist: true
      modifiers:
        attributes:
          Power: [50, converted]
          Precision: [50, converted]
          Toughness: [50, converted]
          Vitality: [50, converted]
          Concentration: [50, converted]
          Condition Damage: [50, converted]
          Expertise: [50, converted]
          Ferocity: [50, converted]
          Healing Power: [50, converted]
      gw2id: 86170
      temporaryBuff: true

- section: Gemstones
  items:

    - id: platinum-doubloon
      text: Platinum Doubloon
      modifiers:
        attributes:
          Concentration: [40, converted]
      gw2id: 24773
      temporaryBuff: false

    - id: ruby-orb
      text: Ruby Orb
      modifiers:
        attributes:
          Power: [20, converted]
          Precision: [14, converted]
          Ferocity: [14, converted]
      gw2id: 24508
      temporaryBuff: false

- section: Placeholder Items
  items:

    - id: no-item-sigil
      text: No Item (doesn't give any stats)
      subText: No Item (doesn't give any stats)
      textOverride: No Item (doesn't give any stats)
      modifiers: {}
      # gw2id intentionally omitted
