- section: Power Food
  items:

    - id: cilantro-lime-sous-vide-steak
      text: Cilantro Lime Sous-Vide Steak
      hasLifesteal: true
      modifiers:
        attributes:
          Power: [100, converted]
          Ferocity: [70, converted]
      gw2id: 91805
      temporaryBuff: false

    - id: sweet-and-spicy-butternut-squash-soup
      text: Sweet and Spicy Butternut Squash Soup / Spicy Moa Wings
      modifiers:
        attributes:
          Power: [100, converted]
          Ferocity: [70, converted]
      gw2id: 41569
      displayIds: [41569, 91917]
      priceIds: [41569, 91917]
      temporaryBuff: false

    - id: avocado-smoothie
      text: Avocado Smoothie
      modifiers:
        attributes:
          Power: [80, converted]
          Ferocity: [60, converted]
      gw2id: 87029
      temporaryBuff: false

    - id: plate-of-coq-au-vin-with-salsa
      text: Plate of Coq Au Vin with Salsa
      hasLifesteal: true
      modifiers:
        attributes:
          Power: [100, converted]
          Precision: [70, converted]
      gw2id: 91709
      temporaryBuff: false

    - id: truffle-steak
      text: Plate of Truffle Steak
      modifiers:
        attributes:
          Power: [100, converted]
          Precision: [70, converted]
      gw2id: 12467
      priceIds: [12467, 79786]
      temporaryBuff: false

    - id: curry-butternut-squash-soup
      text: Bowl of Curry Butternut Squash Soup
      modifiers:
        attributes:
          Precision: [100, converted]
          Ferocity: [70, converted]
      gw2id: 12486
      temporaryBuff: false

    - id: dragon's-breath-bun
      text: Dragon's Breath Bun
      subText: (100% uptime)
      modifiers:
        attributes:
          Power: [200, converted]
          Ferocity: [70, converted]
      gw2id: 43360
      priceIds: [43360, 12469, 41561]
      temporaryBuff: false # sort of

    - id: block-of-tofu
      text: Block of Tofu
      subText: (100% uptime)
      modifiers:
        attributes:
          Power: [100, converted]
          Ferocity: [100, converted]
          Precision: [70, converted]
      gw2id: 96793
      temporaryBuff: false # sort of

    - id: seaweed-salad
      text: Bowl of Seaweed Salad / Bowl of Winterberry Seaweed Salad
      amountData:
        label: '% uptime'
        default: 90
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, mult]
      gw2id: 12471
      displayIds: [12471, 79793]
      priceIds: [12471, 79793]

    - id: jerk-poultry
      text: Plate of Jerk Poultry
      modifiers:
        attributes:
          Power: [150, converted]
      gw2id: 71797
      temporaryBuff: false

    - id: sawgill-mushroom-risotto
      text: Bowl of Sawgill Mushroom Risotto
      modifiers:
        attributes:
          Precision: [150, converted]
      gw2id: 75010
      temporaryBuff: false

    - id: roasted-cactus
      text: Bowl of Prickly Pear Sorbet / Roasted Cactus
      modifiers:
        attributes:
          Ferocity: [100, converted]
      gw2id: 66539
      displayIds: [66539, 66536]
      priceIds: [66539, 66536]
      temporaryBuff: false

    - id: strawberry-cilantro-cheesecake
      text: Strawberry Cilantro Cheesecake
      hasLifesteal: true
      modifiers:
        attributes:
          Concentration: [100, converted]
      gw2id: 91851
      temporaryBuff: false

    - id: fried-golden-dumpling
      text: Fried Golden Dumpling / Cheesecake
      modifiers:
        attributes:
          Concentration: [100, converted]
      gw2id: 68633
      temporaryBuff: false

    - id: slice-of-candied-dragon-roll
      text: Slice of Candied Dragon Roll / Mintberry Swirl Ice Cream
      hasLifesteal: true
      modifiers:
        attributes:
          Precision: [70, converted]
      gw2id: 43362
      displayIds: [43362, 77630]
      priceIds: [43362, 77630, 12457, 36083, 79748]
      temporaryBuff: false

    - id: slice-of-allspice-cake
      text: Slice of Allspice Cake
      hasLifesteal: true
      modifiers:
        attributes:
          Power: [70, converted]
      gw2id: 75126
      temporaryBuff: false

- section: Support Food
  items:

    - id: plate-of-beef-carpaccio-with-salsa-garnish
      text: Plate of Beef Carpaccio with Salsa Garnish
      hasLifesteal: true
      modifiers:
        attributes:
          Concentration: [100, converted]
          Power: [70, converted]
      gw2id: 91862
      temporaryBuff: false

    - id: soul-pastry
      text: Soul Pastry
      modifiers:
        attributes:
          Concentration: [100, converted]
          Power: [70, converted]
      gw2id: 89002
      temporaryBuff: false

    - id: salsa-eggs-benedict
      text: Salsa Eggs Benedict
      hasLifesteal: true
      modifiers:
        attributes:
          Concentration: [100, converted]
          Expertise: [70, converted]
      gw2id: 91847
      temporaryBuff: false

    - id: eggs-benedict
      text: Plate of Eggs Benedict
      modifiers:
        attributes:
          Concentration: [100, converted]
          Expertise: [70, converted]
      gw2id: 91842
      priceIds: [91842, 91950, 91954, 92078]
      temporaryBuff: false

    - id: plate-of-poultry-aspic-with-salsa-garnish
      text: Plate of Poultry Aspic with Salsa Garnish
      hasLifesteal: true
      modifiers:
        attributes:
          Concentration: [100, converted]
          Toughness: [70, converted]
      gw2id: 91704
      temporaryBuff: false

    - id: nopalitos-saute
      text: Bowl of Nopalitos Sauté / Poultry Aspic
      modifiers:
        attributes:
          Concentration: [100, converted]
          Toughness: [70, converted]
      gw2id: 66530
      priceIds: [66530, 66527]
      temporaryBuff: false

    - id: mussels-gnashblade
      text: Plate of Mussels Gnashblade
      modifiers:
        damage:
          Damage Reduction: [10%, mult]
        attributes:
          Concentration: [70, converted]
      gw2id: 74969
      temporaryBuff: false

- section: Heal Food
  items:

    - id: fruit-salad-mint-garnish
      text: Bowl of Fruit Salad with Mint Garnish
      modifiers:
        attributes:
          Healing Power: [100, converted]
          Concentration: [70, converted]
          Outgoing Healing: 10%
      gw2id: 91690
      temporaryBuff: false

    - id: mint-creme-brulee
      text: Mint Creme Brulee
      modifiers:
        attributes:
          Concentration: [100, converted]
          Healing Power: [70, converted]
          Outgoing Healing: 10%
      gw2id: 91743
      temporaryBuff: false

    - id: delicious-rice-ball
      text: Delicious Rice Ball
      modifiers:
        attributes:
          Healing Power: [100, converted]
          Outgoing Healing: 10%
      gw2id: 68634
      temporaryBuff: false

    - id: poultry-satay
      text: Bowl of Poultry Satay
      modifiers:
        attributes:
          Healing Power: [100, converted]
          Concentration: [70, converted]
      gw2id: 87076
      temporaryBuff: false

- section: Condition Food
  items:

    - id: salsa-topped-veggie-flatbread
      text: Salsa-Topped Veggie Flatbread
      hasLifesteal: true
      modifiers:
        attributes:
          Expertise: [100, converted]
          Condition Damage: [70, converted]
      gw2id: 91876
      temporaryBuff: false

    - id: rare-veggie-pizza
      text: Rare Veggie Pizza / Koi Cake
      modifiers:
        attributes:
          Expertise: [100, converted]
          Condition Damage: [70, converted]
      gw2id: 12464
      displayIds: [12464, 43361]
      priceIds: [12464, 43361, 84550, 41565]
      temporaryBuff: false

    - id: cilantro-and-cured-meat-flatbread
      text: Cilantro and Cured Meat Flatbread
      hasLifesteal: true
      modifiers:
        attributes:
          Condition Damage: [100, converted]
          Expertise: [70, converted]
      gw2id: 91878
      temporaryBuff: false

    - id: beef-rendang
      text: Plate of Beef Rendang
      modifiers:
        attributes:
          Condition Damage: [100, converted]
          Expertise: [70, converted]
      gw2id: 86997
      temporaryBuff: false

    - id: fishy-rice-bowl
      text: Fishy Rice Bowl
      modifiers:
        attributes:
          Burning Duration: 15%
          Condition Damage: [70, converted]
      gw2id: 97767
      temporaryBuff: false

    - id: plate-of-kimchi-pancakes
      text: Plate of Kimchi Pancakes
      modifiers:
        attributes:
          Bleeding Duration: 15%
          Condition Damage: [70, converted]
      gw2id: 96578
      temporaryBuff: false

    - id: bowl-of-kimchi-tofu-stew
      text: Bowl of Kimchi Tofu Stew
      modifiers:
        attributes:
          Poison Duration: 15%
          Condition Damage: [70, converted]
      gw2id: 97422
      temporaryBuff: false

    - id: meaty-asparagus-skewer
      text: Meaty Asparagus Skewer
      modifiers:
        attributes:
          Torment Duration: 15%
          Condition Damage: [70, converted]
      gw2id: 95942
      temporaryBuff: false

    - id: meaty-rice-bowl
      text: Meaty Rice Bowl
      modifiers:
        attributes:
          Confusion Duration: 15%
          Condition Damage: [70, converted]
      gw2id: 96916
      temporaryBuff: false

    - id: fire-meat-chili
      text: Bowl of Fire Meat Chili
      modifiers:
        attributes:
          Burning Duration: 15%
          Precision: [70, converted]
      gw2id: 12484
      temporaryBuff: false

    - id: fancy-truffle-burger
      text: Fancy Truffle Burger
      modifiers:
        attributes:
          Poison Duration: 15%
          Precision: [70, converted]
      gw2id: 12463
      temporaryBuff: false

    - id: saffron-stuffed-mushroom
      text: Saffron Stuffed Mushroom
      subText: (100% uptime)
      modifiers:
        attributes:
          Precision: [70, converted]
          Condition Damage: [200, converted]
      gw2id: 12476
      temporaryBuff: false # sort of

    - id: steamed-red-dumpling
      text: Steamed Red Dumpling
      subText: (100% uptime)
      modifiers:
        attributes:
          Power: [200, converted]
          Condition Damage: [140, converted]
      gw2id: 68635
      temporaryBuff: false # sort of

    - id: truffle-risotto
      text: Bowl of Truffle Risotto
      modifiers:
        attributes:
          Condition Damage: [100, converted]
          Precision: [70, converted]
      gw2id: 12465
      temporaryBuff: false

    - id: sweet-and-spicy-beans
      text: Bowl of Sweet and Spicy Beans
      modifiers:
        attributes:
          Expertise: [100, converted]
          Power: [70, converted]
      gw2id: 66528
      priceIds: [66528, 66526]
      temporaryBuff: false

    - id: fire-flank-steak
      text: Plate of Fire Flank Steak
      modifiers:
        attributes:
          Power: [100, converted]
          Condition Damage: [70, converted]
      gw2id: 12466
      temporaryBuff: false

    - id: fancy-potato-and-leek-soup
      text: Bowl of Fancy Potato and Leek Soup
      modifiers:
        attributes:
          Precision: [100, converted]
          Condition Damage: [70, converted]
      gw2id: 12485
      temporaryBuff: false

    - id: prickly-pear-pie
      text: Prickly Pear Pie
      hasLifesteal: true
      modifiers:
        attributes:
          Expertise: [100, converted]
      gw2id: 66538
      priceIds: [66538, 66523]
      temporaryBuff: false

    - id: slice-of-allspice-cake-with-ice-cream
      text: Slice of Allspice Cake with Ice Cream
      hasLifesteal: true
      modifiers:
        attributes:
          Condition Damage: [70, converted]
      gw2id: 76359
      temporaryBuff: false

    - id: ghost-pepper-popper-reaper
      text: Ghost Pepper Popper
      subText: (night; condi reaper)
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Bleeding Coefficient: 16
      wvwModifiers:
        attributes:
          Bleeding Coefficient: 8
      gw2id: 12479
      temporaryBuff: true

- section: Hybrid Food
  items:

    - id: spherified-cilantro-oyster-soup
      text: Spherified Cilantro Oyster Soup
      hasLifesteal: true
      modifiers:
        attributes:
          Power: [45, converted]
          Precision: [45, converted]
          Toughness: [45, converted]
          Vitality: [45, converted]
          Concentration: [45, converted]
          Condition Damage: [45, converted]
          Expertise: [45, converted]
          Ferocity: [45, converted]
          Healing Power: [45, converted]
      gw2id: 91804
      temporaryBuff: false

    - id: spherified-oyster-soup-with-mint-garnish
      text: Spherified Oyster Soup with Mint Garnish
      hasLifesteal: false
      modifiers:
        attributes:
          Power: [45, converted]
          Precision: [45, converted]
          Toughness: [45, converted]
          Vitality: [45, converted]
          Concentration: [45, converted]
          Condition Damage: [45, converted]
          Expertise: [45, converted]
          Ferocity: [45, converted]
          Healing Power: [45, converted]
          Outgoing Healing: 10%
      gw2id: 91748
      temporaryBuff: false

    - id: dragon's-revelry-starcake
      text: Oyster Soup
      textOverride: Oyster Soup
      modifiers:
        attributes:
          Power: [45, converted]
          Precision: [45, converted]
          Toughness: [45, converted]
          Vitality: [45, converted]
          Concentration: [45, converted]
          Condition Damage: [45, converted]
          Expertise: [45, converted]
          Ferocity: [45, converted]
          Healing Power: [45, converted]
      gw2id: 92471
      temporaryBuff: false

    - id: dragons-revelry-starcake-bugged
      text: Dragon's Revelry Starcake
      subText: '(slightly bugged: no conversion)'
      modifiers:
        attributes:
          Power: [45, buff]
          Precision: [45, buff]
          Toughness: [45, buff]
          Vitality: [45, buff]
          Concentration: [45, buff]
          Condition Damage: [45, buff]
          Expertise: [45, buff]
          Ferocity: [45, buff]
          Healing Power: [45, buff]
      gw2id: 43550
      temporaryBuff: false

    - id: birthday-blaster
      text: Birthday Blaster
      modifiers:
        attributes:
          Power: [40, buff]
          Precision: [40, buff]
          Toughness: [40, buff]
          Vitality: [40, buff]
          Concentration: [40, buff]
          Condition Damage: [40, buff]
          Expertise: [40, buff]
          Ferocity: [40, buff]
          Healing Power: [40, buff]
      gw2id: 66961
      temporaryBuff: false

    - id: passion-fruit-tapioca-pudding
      text: Bowl of Passion Fruit Tapioca Pudding
      modifiers:
        attributes:
          Power: [100, converted]
          Healing Power: [70, converted]
      gw2id: 71826
      temporaryBuff: false

    - id: prickly-pear-tapioca-pudding
      text: Bowl of Prickly Pear Tapioca Pudding
      modifiers:
        attributes:
          Condition Damage: [100, converted]
          Healing Power: [70, converted]
      gw2id: 76135
      temporaryBuff: false

    - id: tropical-fruit-salad
      text: Bowl of Tropical Fruit Salad
      modifiers:
        attributes:
          Condition Damage: [70, converted]
          Healing Power: [100, converted]
      gw2id: 36793
      temporaryBuff: false

    - id: spicy-chocolate-cookie
      text: Spicy Chocolate Cookie
      modifiers:
        attributes:
          Healing Power: [100, converted]
          Precision: [70, converted]
      gw2id: 12454
      temporaryBuff: false

- section: Toughness Food
  items:

    - id: spicy-marinated-mushroom
      text: Spicy Marinated Mushroom
      modifiers:
        attributes:
          Power: [100, converted]
          Toughness: [70, converted]
      gw2id: 41563
      temporaryBuff: false

    - id: spicy-herbed-chicken
      text: Plate of Spicy Herbed Chicken
      modifiers:
        attributes:
          Power: [70, converted]
          Toughness: [100, converted]
      gw2id: 41567
      temporaryBuff: false

    - id: truffle-ravioli
      text: Bowl of Truffle Ravioli
      modifiers:
        attributes:
          Precision: [70, converted]
          Toughness: [100, converted]
      gw2id: 12461
      priceIds: [12461, 41564]
      temporaryBuff: false

    - id: plate-of-clear-truffle-and-cilantro-ravioli
      text: Plate of Clear Truffle and Cilantro Ravioli
      hasLifesteal: true
      modifiers:
        attributes:
          Vitality: [100, converted]
          Toughness: [70, converted]
      gw2id: 91735
      temporaryBuff: false

    - id: omnomberry-bread
      text: Loaf of Omnomberry Bread / Ravioli
      modifiers:
        attributes:
          Vitality: [100, converted]
          Toughness: [70, converted]
      gw2id: 12473
      temporaryBuff: false

    - id: kralkachocolate-bar
      text: Kralkachocolate Bar
      modifiers:
        attributes:
          Healing Power: [100, converted]
          Toughness: [70, converted]
      gw2id: 43358
      priceIds: [43358, 38210]
      temporaryBuff: false

    - id: chocolate-tapioca-pudding
      text: Bowl of Chocolate Tapioca Pudding
      modifiers:
        attributes:
          Healing Power: [70, converted]
          Toughness: [100, converted]
      gw2id: 76415
      temporaryBuff: false

    - id: tropical-mousse
      text: Bowl of Tropical Mousse
      modifiers:
        attributes:
          Condition Damage: [70, converted]
          Toughness: [100, converted]
      gw2id: 36841
      temporaryBuff: false

    - id: elon-red
      text: Bowl of "Elon Red"
      modifiers:
        attributes:
          Expertise: [100, converted]
          Toughness: [70, converted]
      gw2id: 82541
      temporaryBuff: false

- section: ???
  items:

    - id: tray-of-decade-desserts
      text: Tray of Decade Desserts
      subText: (untested)
      modifiers:
        conversion:
          Power: {Power: 3%}
          Precision: {Precision: 3%}
          Toughness: {Toughness: 3%}
          Vitality: {Vitality: 3%}
          Concentration: {Concentration: 3%}
          Condition Damage: {Condition Damage: 3%}
          Expertise: {Expertise: 3%}
          Ferocity: {Ferocity: 3%}
          Healing Power: {Healing Power: 3%}
      gw2id: 98924
      temporaryBuff: false

- section: Placeholder Items
  items:

    - id: no-item-food
      text: No Item (doesn't give any stats)
      subText: No Item (doesn't give any stats)
      textOverride: No Item (doesn't give any stats)
      modifiers: {}
      # gw2id intentionally omitted
