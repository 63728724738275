- section: Skills
  items:

    - id: assassins-signet
      text: Assassin's Signet
      subText: passive
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [180, buff]
      gw2id: 13046
      temporaryBuff: activeOutOfCombat

    - id: assassins-signet-active
      text: Assassin's Signet
      subText: while active
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [540, buff]
      gw2id: 13046
      temporaryBuff: true

    - id: signet-of-agility
      text: Signet of Agility
      modifiers:
        attributes:
          Precision: [180, buff]
      gw2id: 13062
      temporaryBuff: false

    - id: vampiric-slash
      text: Vampiric Slash
      subText: siphon effect, vs vulnerable
      amountData:
        label: '/10s'
        default: 0
        defaultInput: ???
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 2115 # 1410 * 1.5
          Siphon Coefficient: 0.3 # 0.2 * 1.5
      gw2id: 73063
      temporaryBuff: true

- section: Deadly Arts
  id: 28
  items:

    - id: dagger-training-base
      text: Dagger Training
      subText: base
      modifiers:
        attributes:
          Power: [80, converted]
      gw2id: 1245
      defaultEnabled: true
      temporaryBuff: false

    - id: dagger-training-dagger
      text: Dagger Training
      subText: with dagger
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [80, converted]
      gw2id: 1245
      defaultEnabled: false
      temporaryBuff: activeOutOfCombat # sometimes...

    - id: deadly-ambition
      text: Deadly Ambition
      modifiers:
        attributes:
          Condition Damage: [180, converted]
      wvwModifiers:
        attributes:
          Condition Damage: [120, converted]
      gw2id: 1164
      defaultEnabled: true
      temporaryBuff: false

    - id: revealed-training-base
      text: Revealed Training
      subText: base
      modifiers:
        attributes:
          Power: [80, buff]
      gw2id: 1704
      defaultEnabled: true
      temporaryBuff: false

    - id: revealed-training-revealed
      text: Revealed Training
      amountData:
        label: '% revealed'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [120, buff]
      gw2id: 1704
      defaultEnabled: false
      temporaryBuff: true

    - id: exposed-weakness
      text: Exposed Weakness
      minor: true
      amountData:
        label: 'conditions'
        default: 10
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [2%, mult]
      gw2id: 1257
      defaultEnabled: true

    - id: potent-poison
      text: Potent Poison
      modifiers:
        damage:
          Outgoing Poison Damage: [33%, mult] # unconfirmed
        attributes:
          Poison Duration: 33%
      wvwModifiers:
        damage:
          Outgoing Poison Damage: [20%, mult] # unconfirmed
        attributes:
          Poison Duration: 5%
      gw2id: 1291
      defaultEnabled: true
      temporaryBuff: false

    - id: executioner
      text: Executioner
      amountData:
        label: '% below 50'
        default: 50
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [20%, mult]
      gw2id: 1269
      defaultEnabled: true

- section: Critical Strikes
  id: 35
  items:

    - id: keen-observer
      text: Keen Observer
      subText: base
      minor: true
      modifiers:
        attributes:
          Critical Chance: 10%
      wvwModifiers:
        attributes:
          Critical Chance: 5%
      gw2id: 1281
      defaultEnabled: true
      temporaryBuff: false

    - id: keen-observer-scholar
      text: Keen Observer
      subText: high health
      minor: true
      modifiers:
        attributes:
          Critical Chance: 5%
      gw2id: 1281
      defaultEnabled: true
      temporaryBuff: true # active out of combat, but does not show up in hero panel

    - id: twin-fangs-scholar
      text: Twin Fangs
      subText: high health (check both)
      modifiers:
        damage:
          Outgoing Critical Damage: [7%, unknown]
      gw2id: 1268
      defaultEnabled: true

    - id: twin-fangs-flank
      text: Twin Fangs
      subText: 100% flanking (check both)
      modifiers:
        attributes:
          Critical Chance: 7%
      gw2id: 1268
      defaultEnabled: true
      temporaryBuff: true

    - id: practiced-tolerance
      text: Practiced Tolerance
      modifiers:
        conversion:
          Ferocity: {Precision: 10%}
      wvwModifiers:
        conversion:
          Ferocity: {Precision: 15%}
      gw2id: 1272
      defaultEnabled: true
      temporaryBuff: false

    - id: ferocious-strikes
      text: Ferocious Strikes
      minor: true
      amountData:
        label: '% above 50'
        default: 50
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Critical Damage: [10%, unknown]
      gw2id: 1282
      defaultEnabled: true

    - id: no-quarter
      text: No Quarter
      amountData:
        label: '% fury'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Ferocity: [250, buff]
      wvwModifiers:
        attributes:
          Ferocity: [300, buff]
      gw2id: 1904
      defaultEnabled: true
      temporaryBuff: true

- section: Shadow Arts
  id: 20
  items:

    - id: leeching-venoms
      text: Leeching Venoms
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 320
          Siphon Coefficient: 0.033
      wvwModifiers:
        attributes:
          Siphon Base Coefficient: 160
          Siphon Coefficient: 0.0165
      gw2id: 1130
      defaultEnabled: true
      temporaryBuff: true

    - id: cloaked-in-shadow
      text: Cloaked in Shadow
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 130
          Siphon Coefficient: 0.04
      gw2id: 1300
      defaultEnabled: true
      temporaryBuff: true

    - id: shadow-siphoning
      text: Shadow Siphoning
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 412
          Siphon Coefficient: 0.01
      wvwModifiers:
        attributes:
          Siphon Base Coefficient: 288
          Siphon Coefficient: 0.07
      minor: true
      gw2id: 1705
      defaultEnabled: true
      temporaryBuff: true

- section: Acrobatics
  id: 54
  items:

    - id: fluid-strikes
      text: Fluid Strikes
      minor: true
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, unknown]
      gw2id: 1242
      defaultEnabled: true

- section: Trickery
  id: 44
  items:

    - id: preparedness
      text: Preparedness
      minor: true
      modifiers:
        attributes:
          Expertise: [150, converted]
      gw2id: 1232
      defaultEnabled: true
      temporaryBuff: false

    - id: lead-attacks
      text: Lead Attacks
      minor: true
      amountData:
        label: 'stacks'
        default: 15
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing All Damage: [1%, add]
      gw2id: 1157
      defaultEnabled: true

    - id: deadly-ambush
      text: Deadly Ambush
      modifiers:
        damage:
          Outgoing Bleeding Damage: [25%, mult] # unconfirmed
      gw2id: 1706
      defaultEnabled: true

- section: Daredevil
  id: 7
  items:

    - id: marauders-resilience
      text: Marauder's Resilience
      subText: melee
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
        conversion:
          Vitality: {Power: 7%}
      gw2id: 1933
      defaultEnabled: true
      temporaryBuff: false

    - id: weakening-strikes
      text: Weakening Strikes
      subText: 100% weakness
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
          Damage Reduction: [10%, unknown]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
          Damage Reduction: [10%, unknown]
      gw2id: 1887
      defaultEnabled: true

    - id: staff-master-base
      text: Staff Master
      subText: base
      modifiers:
        attributes:
          Power: [120, converted]
      gw2id: 1884
      defaultEnabled: true
      temporaryBuff: false

    - id: staff-master-staff
      text: Staff Master
      subText: with staff
      modifiers:
        attributes:
          Power: [120, converted]
      gw2id: 1884
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # sometimes...

    - id: havoc-specialist
      text: Havoc Specialist
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, unknown]
      gw2id: 1893
      defaultEnabled: true

    - id: lotus-training
      text: Lotus Training
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Condition Damage: [15%, add]
      gw2id: 1833
      defaultEnabled: true

    - id: unhindered-combatant
      text: Unhindered Combatant
      subText: 100% uptime
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
      gw2id: 1964
      defaultEnabled: true

    - id: bounding-dodger
      text: Bounding Dodger
      amountData:
        label: '% uptime'
        default: 67
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, add]
      gw2id: 2047
      defaultEnabled: true

- section: Deadeye
  id: 58
  items:

    - id: silent-scope-base
      text: Silent Scope
      subText: base
      modifiers:
        attributes:
          Precision: [120, converted]
      gw2id: 2118
      defaultEnabled: true
      temporaryBuff: false

    - id: silent-scope-rifle
      text: Silent Scope
      subText: with rifle
      modifiers:
        attributes:
          Precision: [120, converted]
      gw2id: 2118
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # sometimes...

    - id: premeditation-base
      text: Premeditation
      subText: base
      modifiers:
        attributes:
          Concentration: [180, buff]
      wvwModifiers:
        attributes:
          Concentration: [60, buff]
      gw2id: 2160
      defaultEnabled: true
      temporaryBuff: false

    - id: premeditation
      text: Premeditation
      amountData:
        label: 'boons'
        default: 10
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [1%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [1%, mult]
      gw2id: 2160
      defaultEnabled: true

    - id: iron-sight
      text: Iron Sight
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
          Damage Reduction: [15%, unknown]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
          Damage Reduction: [10%, unknown]
      gw2id: 2084
      defaultEnabled: true

    - id: be-quick-or-be-killed
      text: Be Quick or Be Killed
      subText: 100% quickness
      modifiers:
        attributes:
          Power: [200, buff]
          Precision: [200, buff]
      gw2id: 2093
      defaultEnabled: true
      temporaryBuff: true

- section: Specter
  id: 71
  items:

    - id: second-opinion-base
      text: Second Opinion
      subText: base
      modifiers:
        conversion:
          Healing Power: {Condition Damage: 7%}
        attributes:
          Condition Damage: [90, unknown]
      gw2id: 2284
      defaultEnabled: true
      temporaryBuff: false

    - id: second-opinion-scepter
      text: Second Opinion
      subText: with scepter
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Condition Damage: [90, unknown]
      gw2id: 2284
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat # sometimes...

    - id: dark-sentry
      text: Dark Sentry
      minor: true
      modifiers:
        attributes:
          Outgoing Healing: 20%
      wvwModifiers:
        attributes:
          Outgoing Healing: 10%
      gw2id: 2272
      defaultEnabled: true
      temporaryBuff: false

    - id: larcenous-torment
      text: Larcenous Torment
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 99
          Siphon Coefficient: 0.005
      gw2id: 2290
      defaultEnabled: true
      temporaryBuff: true

    - id: strength-of-shadows-base
      text: Strength of Shadows
      subText: base
      modifiers:
        conversion:
          Expertise: {Vitality: 13%}
        damage:
          Outgoing Torment Damage: [20%, mult] # unconfirmed
      wvwModifiers:
        conversion:
          Expertise: {Vitality: 13%}
        damage:
          Outgoing Torment Damage: [25%, mult] # unconfirmed
      gw2id: 2264
      defaultEnabled: true
      temporaryBuff: false
