GraphQL ID: presetInfusions
list:

  - name: None
    value:
      - { type: "", count: "" }
      - { type: "", count: "" }

  - name: Power + Precision
    value:
      - { type: "Power", count: "" }
      - { type: "Precision", count: "" }

  - name: Condition Damage + Expertise
    value:
      - { type: "Condition Damage", count: "" }
      - { type: "Expertise", count: "" }

  - name: Condition Damage + Precision
    value:
      - { type: "Condition Damage", count: "" }
      - { type: "Precision", count: "" }

  - name: Healing Power + Concentration
    value:
      - { type: "Healing Power", count: "" }
      - { type: "Concentration", count: "" }
