- section: Skills
  items:

    - id: battle-scars
      text: Battle Scars
      subText: Battle Scars
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 117
          Siphon Coefficient: 0.006
      wvwModifiers:
        attributes:
          Siphon Base Coefficient: 58
          Siphon Coefficient: 0.003
      temporaryBuff: true

    - id: facet-of-nature-assassin
      text: Facet of Nature—Assassin
      subText: Facet of Nature—Assassin
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 53
          Siphon Coefficient: 0.0666
      temporaryBuff: true

    - id: facet-of-darkness-draconic-echo
      text: Facet of Darkness
      subText: draconic echo passive, 100% uptime
      modifiers:
        attributes:
          Critical Chance: 10%
      wvwModifiers:
        attributes:
          Critical Chance: 5%
      gw2id: 28379
      defaultEnabled: false
      temporaryBuff: true

    - id: facet-of-elements-draconic-echo
      text: Facet of Elements
      subText: draconic echo passive
      amountData:
        label: '% uptime'
        default: 20
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Condition Damage: [10%, add] #unconfirmed
      wvwModifiers:
        damage:
          Outgoing Condition Damage: [5%, add] #unconfirmed
      gw2id: 27014

    - id: facet-of-strength-draconic-echo
      text: Facet of Strength
      subText: draconic echo passive
      amountData:
        label: '% uptime'
        default: 20
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, unknown]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [5%, unknown]
      gw2id: 26644

    - id: facet-of-chaos-draconic-echo
      text: Facet of Chaos (Draconic Echo)
      subText: draconic echo passive, 100% uptime
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
      wvwModifiers:
        damage:
          Damage Reduction: [5%, unknown]
      gw2id: 27760
      defaultEnabled: false

    - id: facet-of-nature-draconic-echo
      text: Facet of Nature (Draconic Echo)
      subText: draconic echo passive, 100% uptime
      modifiers:
        attributes:
          Boon Duration: 10%
      wvwModifiers:
        attributes:
          Boon Duration: 5%
      gw2id: 29371
      defaultEnabled: false
      temporaryBuff: true

    - id: burst-of-strength
      text: Burst of Strength
      subText: damage buff only
      amountData:
        label: '% uptime'
        default: 39
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, add]
          Outgoing Condition Damage: [15%, add] #unconfirmed
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, add]
      gw2id: 28113

    - id: vengeful-hammers
      text: Vengeful Hammers
      modifiers:
        damage:
          Damage Reduction: [20%, mult]
      gw2id: 26557

    - id: soulcleaves-summit
      text: Soulcleave's Summit
      subText: siphon only
      amountData:
        label: 'proc/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        attributes:
          Siphon Base Coefficient: 325
          Siphon Coefficient: 0.1
      wvwModifiers:
        attributes:
          Siphon Base Coefficient: 325
          Siphon Coefficient: 0.04
      gw2id: 45773
      temporaryBuff: true

    - id: enchanted-daggers
      text: Enchanted Daggers
      amountData:
        label: '/10s'
        default: 0
        defaultInput: ???
        quantityEntered: 10
      modifiers:
        attributes:
          Siphon Base Coefficient: 6168
          Siphon Coefficient: 0.36
      wvwModifiers:
        attributes:
          Siphon Base Coefficient: 5148
          Siphon Coefficient: 0.3
      gw2id: 26937
      temporaryBuff: true

- section: Corruption
  id: 14
  items:

    - id: acolyte-of-torment
      text: Acolyte of Torment
      modifiers:
        damage:
          Outgoing Torment Damage: [10%, mult]
      gw2id: 1793
      defaultEnabled: true

    - id: seething-malice
      text: Seething Malice
      minor: true
      modifiers:
        attributes:
          Condition Damage: [120, buff]
      gw2id: 1801
      defaultEnabled: true
      temporaryBuff: false

    - id: demonic-resistance
      text: Demonic Resistance
      subText: 100% resistance
      modifiers:
        damage:
          Damage Reduction: [20%, mult]
      wvwModifiers:
        damage:
          Damage Reduction: [10%, mult]
      gw2id: 1726
      defaultEnabled: true

    - id: pact-of-pain
      text: Pact of Pain
      modifiers:
        attributes:
          Condition Duration: 15%
      wvwModifiers:
        attributes:
          Condition Duration: 7%
      gw2id: 1714
      defaultEnabled: true
      temporaryBuff: true

    - id: yearning-empowerment
      text: Yearning Empowerment
      minor: true
      modifiers:
        attributes:
          Condition Duration: 10%
      gw2id: 1744
      defaultEnabled: true
      temporaryBuff: true

- section: Retribution
  id: 9
  items:

    - id: close-quarters
      text: Close Quarters
      subText: ranged
      modifiers:
        damage:
          Damage Reduction: [10%, mult]
      gw2id: 1728
      defaultEnabled: false

    - id: dwarven-battle-training
      text: Dwarven Battle Training
      amountData:
        label: '% weakness'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 1740
      defaultEnabled: true

    - id: determined-resolution
      text: Determined Resolution
      subText: 100% resolution
      minor: true
      modifiers:
        damage:
          Damage Reduction: [10%, mult]
      gw2id: 1713
      defaultEnabled: true

    - id: vicious-reprisal
      text: Vicious Reprisal
      amountData:
        label: '% resolution'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing All Damage: [10%, add]
      gw2id: 1779
      defaultEnabled: true

    - id: versed-in-stone
      text: Versed in Stone
      modifiers:
        conversion:
          Power: {Toughness: 13%}
      wvwModifiers:
        conversion:
          Power: {Toughness: 4%}
      gw2id: 1770
      defaultEnabled: true
      temporaryBuff: false

- section: Invocation
  id: 3
  items:

    - id: rising-tide
      text: Rising Tide
      amountData:
        label: '% scholar'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [7%, mult]
      gw2id: 1761
      defaultEnabled: true

    - id: ferocious-aggression
      text: Ferocious Aggression
      minor: true
      amountData:
        label: '% fury'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing All Damage: [10%, add]
      wvwModifiers:
        damage:
          Outgoing All Damage: [7%, add]
      gw2id: 1758
      defaultEnabled: true

    - id: roiling-mists
      text: Roiling Mists
      subText: 100% fury
      modifiers:
        attributes:
          Critical Chance: 25%
      wvwModifiers:
        attributes:
          Critical Chance: 20%
      gw2id: 1719
      defaultEnabled: true
      temporaryBuff: true

- section: Devastation
  id: 15
  items:

    - id: unsuspecting-strikes
      text: Unsuspecting Strikes
      amountData:
        label: '% above 80'
        default: 20
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [20%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 1767
      defaultEnabled: true

    - id: destructive-impulses-base
      text: Destructive Impulses
      subText: base
      minor: true
      modifiers:
        damage:
          Outgoing All Damage: [5%, add]
      gw2id: 1724
      defaultEnabled: true

    - id: destructive-impulses-dual
      text: Destructive Impulses
      subText: bonus
      minor: true
      amountData:
        label: '% dual wielding'
        default: 50
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing All Damage: [2.5%, add]
      wvwModifiers:
        damage:
          Outgoing All Damage: [5%, add]
      gw2id: 1724
      defaultEnabled: true

    - id: notoriety
      text: Notoriety
      amountData:
        label: 'might'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Power: [250, buff]
          Condition Damage: [-250, buff]
      gw2id: 1765
      defaultEnabled: true
      temporaryBuff: true

    - id: targeted-destruction
      text: Targeted Destruction
      amountData:
        label: 'vulnerability'
        default: 25
        quantityEntered: 25
      minor: true
      modifiers:
        damage:
          Outgoing Strike Damage: [12.5%, mult]
      gw2id: 1792
      defaultEnabled: true

    - id: swift-termination
      text: Swift Termination
      amountData:
        label: '% below 50'
        default: 50
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [20%, mult]
      gw2id: 1800
      defaultEnabled: true

- section: Salvation
  id: 12
  items:

    - id: life-attunement
      text: Life Attunement
      minor: true
      modifiers:
        attributes:
          Healing Power: [120, converted] # tested by Leder
        conversion:
          Concentration: {Healing Power: 7%}
      wvwModifiers:
        attributes:
          Healing Power: [120, converted]
        conversion:
          Concentration: {Healing Power: 4%}
      gw2id: 1821
      defaultEnabled: true
      temporaryBuff: false

    - id: invoking-harmony
      text: Invoking Harmony
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Outgoing Healing: 20%
      wvwModifiers:
        attributes:
          Outgoing Healing: 10%
      gw2id: 1823
      defaultEnabled: true
      temporaryBuff: true

    - id: serene-rejuvenation
      text: Serene Rejuvenation
      minor: true
      modifiers:
        attributes:
          Outgoing Healing: 20%
      wvwModifiers:
        attributes:
          Outgoing Healing: 15%
      gw2id: 1814
      defaultEnabled: true
      temporaryBuff: false

    - id: unyielding-devotion
      text: Unyielding Devotion
      subText: 100%
      modifiers:
        damage:
          Damage Reduction: [15%, mult]
      gw2id: 1825
      defaultEnabled: false

- section: Herald
  id: 52
  items:

    - id: draconic-fortitude
      text: Draconic Fortitude
      minor: true
      modifiers:
        attributes:
          Maximum Health: 10%
      gw2id: 1737
      defaultEnabled: true
      temporaryBuff: false

    - id: hardening-persistence
      text: Hardening Persistence
      amountData:
        label: 'upkeep'
        default: 10
        quantityEntered: 1
        disableBlacklist: true
      modifiers:
        damage:
          Damage Reduction: [1.5%, mult]
      gw2id: 1730
      defaultEnabled: false

    - id: elevated-compassion
      text: Elevated Compassion
      modifiers:
        conversion:
          Concentration: {Power: 13%}
      gw2id: 1746
      defaultEnabled: true
      temporaryBuff: false

    - id: reinforced-potency-base
      text: Reinforced Potency
      subText: base
      minor: true
      modifiers:
        attributes:
          Concentration: [240, converted]
      wvwModifiers:
        attributes:
          Concentration: [60, converted]
      gw2id: 1788
      defaultEnabled: true
      temporaryBuff: false

    - id: reinforced-potency-boons
      text: Reinforced Potency
      minor: true
      amountData:
        label: 'boons'
        default: 10
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [1.5%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [1%, mult]
      gw2id: 1788
      defaultEnabled: true

    - id: forceful-persistence-nonherald
      text: Forceful Persistence
      subText: non herald upkeep
      amountData:
        label: '% uptime'
        default: 43
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [20%, add] # add according to wiki
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [15%, add] # add according to wiki
      gw2id: 1803
      defaultEnabled: true

    - id: forceful-persistence-herald
      text: Forceful Persistence
      subText: per herald upkeep
      amountData:
        label: 'average stacks'
        default: 2.4
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [7%, add] # add according to wiki
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [4%, add]
      gw2id: 1803
      defaultEnabled: true

- section: Renegade
  id: 63
  items:

    - id: ambush-commander
      text: Ambush Commander
      minor: true
      amountData:
        label: "Kalla's fervor"
        default: 5
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing All Damage: [2%, add]
      gw2id: 2181
      defaultEnabled: true

    - id: blood-fury
      text: Blood Fury
      subText: 100% fury
      modifiers:
        attributes:
          Bleeding Duration: 25%
      gw2id: 2079
      defaultEnabled: true
      temporaryBuff: true

    - id: heartpiercer
      text: Heartpiercer
      modifiers:
        damage:
          Outgoing Bleeding Damage: [25%, mult]
      gw2id: 2092
      defaultEnabled: true

    - id: all-for-one
      text: All for One
      amountData:
        label: '% uptime'
        default: 0
        defaultInput: ???
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add] # https://discord.com/channels/380901000200060929/380904097592901635/1255204952398102549
      gw2id: 2108
      defaultEnabled: true

    - id: brutal-momentum
      text: Brutal Momentum
      subText: full endurance
      minor: true
      modifiers:
        attributes:
          Critical Chance: 33%
      gw2id: 2142
      defaultEnabled: true
      temporaryBuff: false

    - id: lasting-legacy
      text: Lasting Legacy
      amountData:
        label: "Kalla's fervor"
        default: 5
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing All Damage: [1%, add]
      gw2id: 2100
      defaultEnabled: true

- section: Vindicator
  id: 69
  items:

    - id: leviathan-strength
      text: Leviathan Strength
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 2258
      defaultEnabled: true

    - id: empire-divided-above
      text: Empire Divided
      subText: above
      minor: true
      amountData:
        label: '% above 50'
        default: 100
        quantityEntered: 100
      modifiers:
        attributes:
          Power: [240, converted]
      gw2id: 2229
      defaultEnabled: true
      temporaryBuff: activeOutOfCombat

    - id: empire-divided-below
      text: Empire Divided
      subText: below
      minor: true
      amountData:
        label: '% below 50'
        default: 0
        quantityEntered: 100
      modifiers:
        attributes:
          Healing Power: [240, converted]
      gw2id: 2229
      defaultEnabled: true
      temporaryBuff: true

    - id: forerunner-of-death
      text: Forerunner of Death
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [25%, add]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [15%, add]
      gw2id: 2257
      defaultEnabled: true
