# Ask permission before adding authors to this file, it's polite!
# Omit authors from this list if they would rather be anonymous.

Costa Primo:
  authorUrl: https://www.youtube.com/channel/UCC1yHilnsxIy4wIgflg-pXw

'[NA] Crone':
  authorUrl: https://www.youtube.com/channel/UCUx_JZ1pQFkbIH-KtOUPKgA

Fennec:
  authorUrl: https://www.youtube.com/channel/UCiBaiZgGE0M8lrg_xIVor2A

Fines:
  authorUrl: https://www.youtube.com/channel/UCGg3-vKGC8amnpkli7i_qTQ

Incera:
  authorUrl: https://www.youtube.com/channel/UC6K-mvcADSGSXcvhcxvHYhQ

Iskarel:
  authorUrl: https://www.youtube.com/channel/UCUVtCgtHzAQzdZL2cKrxfNA

'[Aves] Leder':
  authorUrl: https://www.youtube.com/channel/UC1tugO9R0bDZk60OEMZ2GgA

Left:
  authorUrl: https://www.youtube.com/channel/UCj-iaC7tUKWh3XlmPA0g6TQ

Ryan:
  authorUrl: https://www.youtube.com/channel/UCOo_7XVLMH2-s80ZIBMc4qg

REMagic:
  authorUrl: https://www.youtube.com/channel/UCA2rppcdbx1a2CQxS6BqwMQ

Support Hero:
  authorUrl: https://www.youtube.com/channel/UChXnJbnYK_j3h9689UtKAyg

'[CnD] Aphrodite Lady':
  authorUrl: https://www.youtube.com/channel/UC5pvam7aCn2A8QbDMn2rOhw

'[CnD] Bekko':
  authorUrl: https://www.youtube.com/channel/UCe7Wkk8PxtLRRn-p5SXaU1w

'[CnD] DaedDee':
  authorUrl: https://www.youtube.com/channel/UCHqq81PPmc7A1iQQ3JApUuA

'[CnD] Lunar':
  authorUrl: https://www.youtube.com/channel/UCLAQLxxF_RIXr_ggFzW6M2A

'[CnD] Tipcat':
  authorUrl: https://www.youtube.com/channel/UC3d0AKF75J8FaqyV9JyEWxg

'[CnD] Xyonon':
  authorUrl: https://www.youtube.com/channel/UC8JHT0vruIu7jesAqJVadeA

'[Inn] Xorxy':
  authorUrl: https://www.youtube.com/channel/UC89Ez6t-l4BTlePkSEkgNCg

Snow Crows: {}

'[SC] Luna':
  authorUrl: https://www.youtube.com/channel/UCfWxpxdyWiyC2pm-XFxn7Jw

'[SC] Roul':
  authorUrl: https://www.youtube.com/channel/UCXhVSyj7F7zIkQzcISynA7A

'[SC] Westet':
  authorUrl: https://www.youtube.com/channel/UCXXWbTvk_eLahxOrExqMrVw

'[XVII] ster':
  authorUrl: https://www.youtube.com/channel/UCIceQJPuG3CRnt4xnczTE2g

'[dT/XVII] Seatek':
  authorUrl: https://www.youtube.com/channel/UC8L6s_rOwVdzO6oW8gABfDQ

Masel:
  authorUrl: https://www.youtube.com/channel/UChVEG6ckuAgGs5OyA6VeisA

Umbra:
  authorUrl: https://www.youtube.com/user/PixzelSoundz

Luxroy:
  authorUrl: https://www.youtube.com/c/LuxroyLux

'[Vs] Nico':
  authorUrl: https://www.youtube.com/channel/UCYi2lmZ-yl4qw38OdQTtaFw

'[SC] Obvious Trash':
  authorUrl: https://www.youtube.com/channel/UClxCenVE9f_59CVNc5F5K_Q

'beng / L3m0n':
  authorUrl: https://www.youtube.com/user/L3m0n1302

'Baym':
  authorUrl: https://www.youtube.com/channel/UCBeQ3pDAmg08ecPfk7RSWoA

'vpo':
  authorUrl: https://www.youtube.com/channel/UCHTtUZf8ZZjkVaNr10pkCUA

'Pufin':
  authorUrl: https://www.youtube.com/user/xheatx

'Dánmander':
  authorUrl: https://www.youtube.com/c/D%C3%A1nmander

'[InS] Laoshi':
  authorUrl: https://www.youtube.com/channel/UC4EYbGXqdccCiH6rVZSjqxA

Baseraver:
  authorUrl: https://www.youtube.com/user/BlackUltimate1

Evo:
  authorUrl: https://www.youtube.com/c/Evo_MR

DaniDomum:
  authorUrl: https://www.youtube.com/channel/UCCqG4Gz7gs03wnGpY-M5i9w

'[SC] Fallen':
  authorUrl: https://www.youtube.com/@fallen7191

FidelOo:
  authorUrl: https://www.youtube.com/@fideloo24

Zargholl:
  authorUrl: https://www.youtube.com/@_zargholl

Cody:
  authorUrl: https://www.youtube.com/@CodyHan

Sarulias:
  authorUrl: https://www.youtube.com/@saruliasgw2486

Derm:
  authorUrl: https://www.youtube.com/@derm6513

Aralyth:
  authorUrl: https://www.youtube.com/@op_ara

Jan:
  authorUrl: https://www.youtube.com/@janvandum

Mastro:
  authorUrl: https://www.youtube.com/@Mastro1363

'[SC] Trace':
  authorUrl: https://www.youtube.com/@TraceSC

Shavy:
  authorUrl: https://www.youtube.com/@shavy3967

Andy:
  authorUrl: https://www.youtube.com/@andygw2

Pip:
  authorUrl: https://www.youtube.com/@PipGW2

Ostro:
  authorUrl: https://www.youtube.com/@Saturnity

Tiramichu:
  authorUrl: https://www.youtube.com/@cepheus4082

Cemox:
  authorUrl: https://www.youtube.com/@cemox2844

'Saint Mighty':
  authorUrl: https://www.youtube.com/@SntMighty

Linsi:
  authorUrl: https://www.youtube.com/@linsiana

Botinator:
  authorUrl: https://www.youtube.com/@botinator155
