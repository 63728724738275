- section: Skills
  items:

    - id: barrier-signet
      text: Barrier Signet
      modifiers:
        damage:
          Damage Reduction: [10%, unknown]
      gw2id: 63262

    - id: barrier-signet-traited
      text: Barrier Signet
      subText: traited
      modifiers:
        damage:
          Damage Reduction: [12%, unknown]
      gw2id: 63262

    - id: force-signet
      text: Force Signet
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add] # tested by Crone
      gw2id: 63253

    - id: force-signet-traited
      text: Force Signet
      subText: traited
      modifiers:
        damage:
          Outgoing Strike Damage: [12%, add] # tested by Crone
      gw2id: 63253

    - id: superconducting-signet
      text: Superconducting Signet
      modifiers:
        damage:
          Outgoing Condition Damage: [10%, add] #unconfirmed
      gw2id: 63113

    - id: superconducting-signet-traited
      text: Superconducting Signet
      subText: traited
      modifiers:
        damage:
          Outgoing Condition Damage: [12%, add] #unconfirmed
      gw2id: 63113

- section: Explosives
  id: 6
  items:

    - id: glass-cannon
      text: Glass Cannon
      amountData:
        label: '% scholar'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 1882
      defaultEnabled: true

    - id: explosive-temper
      text: Explosive Temper
      amountData:
        label: 'stacks'
        default: 10
        quantityEntered: 1
      modifiers:
        attributes:
          Ferocity: [20, buff]
      gw2id: 1892
      defaultEnabled: true
      temporaryBuff: true

    - id: blast-shield
      text: Blast Shield
      modifiers:
        conversion:
          Vitality: {Power: 10%}
      gw2id: 1944
      defaultEnabled: true
      temporaryBuff: false

    - id: shaped-charge
      text: Shaped Charge
      minor: true
      amountData:
        label: 'vulnerability'
        default: 25
        quantityEntered: 25
      modifiers:
        damage:
          Outgoing Strike Damage: [12.5%, mult]
      gw2id: 429
      defaultEnabled: true

    - id: big-boomer
      text: Big Boomer
      subText: 100%
      modifiers:
        damage:
          Outgoing Strike Damage: [15%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [10%, mult]
      gw2id: 1947
      defaultEnabled: true

- section: Firearms
  id: 38
  items:

    - id: sharpshooter
      text: Sharpshooter
      subText: per hit (including non critical)
      minor: true
      amountData:
        label: 'hit/s'
        default: 0
        defaultInput: ???
        quantityEntered: 1
      modifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 100%}
      wvwModifiers:
        conversionAfterBuffs:
          Bleeding Coefficient: {Critical Chance: 33%}
      gw2id: 515
      defaultEnabled: false
      temporaryBuff: true

    - id: chemical-rounds
      text: Chemical Rounds
      modifiers:
        attributes:
          Condition Damage: [120, buff]
      gw2id: 1878
      defaultEnabled: true
      temporaryBuff: false

    - id: high-caliber
      text: High Caliber
      subText: 100% melee
      modifiers:
        attributes:
          Critical Chance: 15%
      gw2id: 1914
      defaultEnabled: true
      temporaryBuff: true

    - id: hematic-focus
      text: Hematic Focus
      modifiers:
        attributes:
          Critical Chance: 15%
      wvwModifiers:
        attributes:
          Critical Chance: 10%
      minor: true
      gw2id: 536
      defaultEnabled: true
      temporaryBuff: true

    - id: thermal-vision
      text: Thermal Vision
      subText: base
      modifiers:
        attributes:
          Expertise: [150, converted]
      wvwModifiers:
        attributes:
          Expertise: [60, converted]
      gw2id: 2006
      defaultEnabled: true
      temporaryBuff: false

    - id: thermal-vision-buff
      text: Thermal Vision
      subText: buff
      amountData:
        label: '% uptime'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Condition Damage: [5%, add]
      gw2id: 2006
      defaultEnabled: true

    - id: no-scope
      text: No Scope
      subText: 100% fury
      modifiers:
        attributes:
          Ferocity: [150, buff]
      gw2id: 1923
      defaultEnabled: true
      temporaryBuff: true

    - id: serrated-steel
      text: Serrated Steel
      modifiers:
        attributes:
          Bleeding Duration: 33%
      wvwModifiers:
        attributes:
          Bleeding Duration: 15%
      minor: true
      gw2id: 516
      defaultEnabled: true
      temporaryBuff: false

    - id: modified-ammunition
      text: Modified Ammunition
      amountData:
        label: 'conditions'
        default: 10
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [2%, mult]
      wvwModifiers:
        damage:
          Outgoing Strike Damage: [1.5%, mult]
      gw2id: 526
      defaultEnabled: true

    - id: incendiary-powder
      text: Incendiary Powder
      modifiers:
        attributes:
          Burning Duration: 33%
      wvwModifiers:
        attributes:
          Burning Duration: 10%
      gw2id: 433
      defaultEnabled: true
      temporaryBuff: false

- section: Inventions
  id: 47
  items:

    - id: over-shield
      text: Over Shield
      modifiers:
        damage:
          Damage Reduction: [6.6%, unknown]
      gw2id: 394
      defaultEnabled: true

    - id: energy-amplifier
      text: Energy Amplifier
      subText: 100% regeneration
      modifiers:
        attributes:
          Power: [250, buff]
          Healing Power: [250, buff]
      minor: true
      gw2id: 519
      defaultEnabled: true
      temporaryBuff: true

- section: Alchemy
  id: 29
  items:

    - id: health-insurance
      text: Health Insurance
      subText: in med kit
      modifiers:
        attributes:
          Outgoing Healing: 20%
      wvwModifiers:
        attributes:
          Outgoing Healing: 7%
      gw2id: 521
      defaultEnabled: false
      temporaryBuff: true

    - id: compounding-chemicals
      text: Compounding Chemicals
      modifiers:
        attributes:
          Concentration: [240, buff]
      wvwModifiers:
        attributes:
          Concentration: [75, buff]
      minor: true
      gw2id: 413
      defaultEnabled: true
      temporaryBuff: false

    - id: iron-blooded
      text: Iron Blooded
      amountData:
        label: 'boons'
        default: 10
        quantityEntered: 1
        disableBlacklist: true
      modifiers:
        damage:
          Damage Reduction: [2%, unknown]
      gw2id: 1854
      defaultEnabled: true

- section: Tools
  id: 21
  items:

    - id: takedown-round
      text: Takedown Round
      amountData:
        label: '% uptime'
        default: 0
        defaultInput: ???
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, mult] # tested by Crone
      gw2id: 1832
      defaultEnabled: true

    - id: excessive-energy
      text: Excessive Energy
      amountData:
        label: '% vigor'
        default: 100
        quantityEntered: 100
      modifiers:
        damage:
          Outgoing Strike Damage: [10%, add]
      minor: true
      gw2id: 1936
      defaultEnabled: true

- section: Scrapper
  id: 43
  items:

    - id: object-in-motion
      text: Object in Motion
      amountData:
        label: 'active effects'
        default: 2
        quantityEntered: 1
      modifiers:
        damage:
          Outgoing Strike Damage: [5%, mult]
      gw2id: 1860
      defaultEnabled: true

    - id: impact-savant
      text: Impact Savant
      modifiers: {}
      minor: true
      gw2id: 1877
      defaultEnabled: true

    - id: kinetic-accelerators
      text: Kinetic Accelerators
      modifiers:
        conversion:
          Concentration: {Power: 13%}
      wvwModifiers:
        conversion:
          Concentration: {Power: 10%}
      gw2id: 2052
      defaultEnabled: true
      temporaryBuff: false

    - id: applied-force
      text: Applied Force
      amountData:
        label: 'might'
        default: 25
        quantityEntered: 25
      modifiers:
        attributes:
          Power: [750, buff]
      wvwModifiers:
        attributes:
          Power: [375, buff]
      gw2id: 1849
      defaultEnabled: true
      temporaryBuff: true

- section: Holosmith
  id: 57
  items:
    - id: lasers-edge
      text: Laser's Edge
      amountData:
        label: Average Heat
        default: 112
        quantityEntered: 150
      modifiers:
        damage:
          Outgoing Strike Damage: [22.5%, mult] # 0.15 per 1 heat
      minor: true
      gw2id: 2122
      defaultEnabled: true

  # mechanist damage is no longer accurate (even on condi)
  # see: https://wiki.guildwars2.com/wiki/Game_updates/2023-02-14#Mechanist
- section: Mechanist
  id: 70
  note: Damage dealt by the mechanist mech is not currently simulated accurately. It inherits some, but not all, player stats and bonuses.
  items: []
