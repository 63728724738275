GraphQL ID: presetExtras
list:

  - name: Power (Fractal)
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-both": {}
          },
          "Enhancement": {
            "slaying-potion": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        }
      }

  - name: Power (Raid)
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power (Raid, fireworks)
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "fireworks-relic": {
              "amount": "100"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {},
            "furious-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power (Raid, claw)
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "claw-relic": {
              "amount": "100"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {},
            "furious-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power (Raid, furious)
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {},
            "furious-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power (Raid, air)
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {},
            "air": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }


  # just reduced lifesteal frequency
  - name: Bladesworn
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {},
            "furious-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": "3.1"
      }

  # air-only approximation of air/hydro split
  - name: Power Berserker
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {},
            "air": {
              "amount": "4.1"
            }
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Boon (Raid)
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "leadership": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {},
            "accuracy": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {},
            "concentration": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {},
            "furious-sharpening-stone": {},
            "holographic-super-cheese": {},
            "potent-lucent-oil": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "plate-of-beef-carpaccio-with-salsa-garnish": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Boon (Fractal)
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "leadership": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {},
            "accuracy": {}
          },
          "Sigil2": {
            "impact/night/slaying-both": {},
            "accuracy": {},
            "concentration": {}
          },
          "Enhancement": {
            "slaying-potion": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "plate-of-beef-carpaccio-with-salsa-garnish": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  # no item sigil represents use of water sigil
  - name: Heal
    value:
      {
        "extras": {
          "Runes": {
            "monk": {},
            "water": {}
          },
          "Relics": {},
          "Sigil1": {
            "concentration": {},
            "no-item-sigil": {}
          },
          "Sigil2": {
            "transference": {}
          },
          "Enhancement": {
            "bountiful-maintenance-oil": {}
          },
          "Nourishment": {
            "fruit-salad-mint-garnish": {}
          }
        }
      }

  - name: Alacrity Staff Mirage
    profession: Mirage
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "weaver": {},
            "berserker": {},
            "renegade": {},
            "leadership": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {},
          "Enhancement": {
            "toxic-maintenance-oil": {},
            "master-tuning-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "salsa-topped-veggie-flatbread": {}
          }
        }
      }

  - name: DPS Mirage (Raid)
    profession: Mirage
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "weaver": {},
            "berserker": {},
            "renegade": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": "0.475"
            }
          },
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {},
          "Enhancement": {
            "master-tuning-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "salsa-topped-veggie-flatbread": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Boon Condi Chrono
    profession: Chronomancer
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "mischief-ineptitude": {
              "amount": "0.9"
            },
            "concentration": {}
          },
          "Runes": {
            "perplexity": {}
          },
          "Relics": {},
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "spherified-cilantro-oyster-soup": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {},
            "master-tuning-crystal": {}
          }
        }
      }

  - name: Condi Chrono DPS
    profession: Chronomancer
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "mischief-ineptitude": {
              "amount": "0.9"
            }
          },
          "Runes": {
            "perplexity": {}
          },
          "Relics": {},
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {},
            "master-tuning-crystal": {}
          }
        }
      }

  - name: Condi Virtuoso Dueling
    profession: Virtuoso
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {},
            "agony": {}
          },
          "Sigil2": {
            "earth": {
              "amount": "4.3"
            }
          },
          "Runes": {
            "krait": {}
          },
          "Relics": {
            "aristocracy-relic": {}
          },
          "Nourishment": {
            "plate-of-kimchi-pancakes": {},
            "fancy-potato-and-leek-soup": {},
            "spherified-cilantro-oyster-soup": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Virtuoso Chaos
    profession: Virtuoso
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "earth": {
              "amount": "4.3"
            }
          },
          "Runes": {
            "krait": {}
          },
          "Relics": {},
          "Nourishment": {
            "spherified-cilantro-oyster-soup": {},
            "fancy-potato-and-leek-soup": {},
            "truffle-risotto": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Quickbrand (Raid)
    profession: Firebrand
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "firebrand": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {},
            "concentration": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: DH Virtues (Raid)
    profession: Dragonhunter
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {},
            "dragonhunter-relic": {
              "amount": "73.6"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {},
            "furious-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: DH Virtues (Fractal)
    profession: Dragonhunter
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {},
            "dragonhunter-relic": {
              "amount": "73.6"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-both": {}
          },
          "Enhancement": {
            "slaying-potion": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {}
          }
        }
      }

  - name: Power Willbender Virtues (Fractal)
    profession: Willbender
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-both": {}
          },
          "Enhancement": {
            "slaying-potion": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {}
          }
        }
      }

  - name: Power Alacrity Willbender (Raid)
    profession: Willbender
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "leadership": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "accuracy": {}
          },
          "Enhancement": {
            "furious-sharpening-stone": {},
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Willbender
    profession: Willbender
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {},
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": "0.48"
            }
          },
          "Nourishment": {
            "fishy-rice-bowl": {},
            "cilantro-and-cured-meat-flatbread": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Alacrity Willbender
    profession: Willbender
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "geomancy-torment-cwb-alac": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {},
            "leadership": {}
          },
          "Relics": {},
          "Nourishment": {
            "fishy-rice-bowl": {},
            "cilantro-and-cured-meat-flatbread": {},
            "spherified-cilantro-oyster-soup": {}
          },
          "Enhancement": {
            "toxic-maintenance-oil": {},
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Firebrand
    profession: Firebrand
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": ""
            },
            "aristocracy-relic": {}
          },
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "smoldering": {},
            "malice": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "fishy-rice-bowl": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Quickess Condi Firebrand
    profession: Firebrand
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "smoldering": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "balthazar": {},
            "berserker": {},
            "firebrand": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": "0.41"
            }
          },
          "Nourishment": {
            "fishy-rice-bowl": {},
            "salsa-topped-veggie-flatbread": {},
            "cilantro-and-cured-meat-flatbread": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {},
            "magnanimous-tuning-crystal": {},
            "toxic-maintenance-oil": {},
            "magnanimous-maintenance-oil": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Hybrid FB Traveler
    profession: Firebrand
    value:
      {
        "extras": {
          "Runes": {},
          "Relics": {},
          "Sigil1": {
            "bursting": {},
            "concentration": {}
          },
          "Sigil2": {
            "smoldering": {}
          },
          "Enhancement": {
            "master-tuning-crystal": {},
            "magnanimous-maintenance-oil": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Berserker
    profession: Berserker
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": ""
            }
          },
          "Sigil1": {},
          "Sigil2": {},
          "Enhancement": {
            "master-tuning-crystal": {},
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {},
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": "3.7"
      }

  - name: Condi Quickness Bers
    profession: Berserker
    value:
      {
        "extras": {
          "Runes": {
            "firebrand": {},
            "trapper": {},
            "nightmare": {},
            "tempest": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {},
          "Sigil2": {},
          "Enhancement": {
            "toxic-maintenance-oil": {},
            "master-tuning-crystal": {}
          },
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {},
            "cilantro-and-cured-meat-flatbread": {},
            "spherified-cilantro-oyster-soup": {}
          }
        }
      }

  - name: Alacrity Power Tempest (Fractal)
    profession: Tempest
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {}
          },
          "Relics": {
            "fireworks-relic": {
              "amount": "100"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-both": {}
          },
          "Enhancement": {
            "slaying-potion": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Alacrity Power Tempest (Raid)
    profession: Tempest
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {}
          },
          "Relics": {
            "fireworks-relic": {
              "amount": "100"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Weaver Sword
    profession: Weaver
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {},
          "Sigil1": {},
          "Sigil2": {},
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {}
          }
        }
      }

  - name: Condi Weaver Dagger
    profession: Weaver
    value:
      {
        "extras": {
          "Sigil1": {
            "earth": {}
          },
          "Sigil2": {
            "bursting": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {},
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        }
      }

  # Torment procs chosen to make log get 0 stacks
  - name: Condi Weaver Scepter
    profession: Weaver
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {
            "earth": {}
          },
          "Sigil2": {
            "smoldering": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {},
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Weaver
    profession: Weaver
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {
            "earth": {}
          },
          "Sigil2": {
            "torment": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {},
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Hybrid Weaver (Fractal)
    profession: Weaver
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {},
          "Sigil1": {
            "impact/night/slaying-both": {}
          },
          "Sigil2": {
            "bursting": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "spherified-cilantro-oyster-soup": {}
          }
        }
      }

  - name: Hybrid Weaver (Raid)
    profession: Weaver
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "bursting": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "spherified-cilantro-oyster-soup": {}
          }
        }
      }

  - name: Alacrity Hybrid Tempest
    profession: Tempest
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {},
            "leadership": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": "0.4"
            }
          },
          "Sigil1": {
            "bursting": {},
            "smoldering": {}
          },
          "Sigil2": {
            "torment": {
              "amount": ""
            },
            "concentration": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {},
            "toxic-maintenance-oil": {}
          },
          "Nourishment": {
            "spherified-cilantro-oyster-soup": {},
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Tempest
    profession: Tempest
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": ""
            }
          },
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "smoldering": {},
            "earth": {},
            "malice": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {},
            "plate-of-kimchi-pancakes": {},
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Quickness Catalyst (Raid)
    profession: Catalyst
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "firebrand": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {},
            "concentration": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Quickness Untamed
    profession: Untamed
    value:
      {
        "extras": {
          "Sigil1": {
            "malice": {}
          },
          "Sigil2": {
            "earth": {},
            "bursting": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "krait": {},
            "mad-king": {},
            "afflicted": {},
            "thorns": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Nourishment": {
            "plate-of-kimchi-pancakes": {},
            "salsa-topped-veggie-flatbread": {},
            "cilantro-and-cured-meat-flatbread": {},
            "bowl-of-kimchi-tofu-stew": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {},
            "magnanimous-tuning-crystal": {},
            "master-tuning-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Quickness Untamed (Raid)
    profession: Untamed
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "firebrand": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "air": {
              "amount": ""
            },
            "accuracy": {},
            "impact/night/slaying-only-3": {},
            "concentration": {}
          },
          "Enhancement": {
            "potent-lucent-oil": {},
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Slb
    profession: Soulbeast
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {},
            "earth": {}
          },
          "Sigil2": {
            "agony": {},
            "earth": {},
            "malice": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "krait": {},
            "mad-king": {},
            "afflicted": {},
            "thorns": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "salsa-topped-veggie-flatbread": {},
            "bowl-of-kimchi-tofu-stew": {},
            "plate-of-kimchi-pancakes": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Hybrid Slb
    profession: Soulbeast
    value:
      {
        "extras": {
          "Sigil1": {
            "earth": {}
          },
          "Sigil2": {
            "bursting": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "krait": {},
            "mad-king": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": ""
            }
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "plate-of-kimchi-pancakes": {},
            "spherified-cilantro-oyster-soup": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Druid
    profession: Druid
    value:
      {
        "extras": {
          "Sigil1": {
            "agony": {},
            "bursting": {},
            "malice": {}
          },
          "Sigil2": {},
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "krait": {},
            "afflicted": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "salsa-topped-veggie-flatbread": {},
            "plate-of-kimchi-pancakes": {},
            "bowl-of-kimchi-tofu-stew": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Holo (Raid)
    profession: Holosmith
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "fireworks-relic": {
              "amount": "92.6"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Holo
    profession: Holosmith
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {
            "malice": {},
            "earth": {}
          },
          "Sigil2": {
            "bursting": {}
          },
          "Enhancement": {
            "master-tuning-crystal": {},
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {}
          }
        }
      }

  - name: Condi Quickness Scrapper
    profession: Scrapper
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {},
            "firebrand": {}
          },
          "Relics": {},
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "malice": {}
          },
          "Enhancement": {
            "master-tuning-crystal": {},
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {},
            "cilantro-and-cured-meat-flatbread": {},
            "spherified-cilantro-oyster-soup": {}
          }
        }
      }

  - name: Alacrity Condi Renegade
    profession: Renegade
    value:
      {
        "extras": {
          "Sigil1": {},
          "Sigil2": {},
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "leadership": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Nourishment": {
            "meaty-asparagus-skewer": {},
            "salsa-eggs-benedict": {},
            "spherified-cilantro-oyster-soup": {},
            "cilantro-and-cured-meat-flatbread": {}
          },
          "Enhancement": {
            "toxic-maintenance-oil": {},
            "master-tuning-crystal": {},
            "toxic-focusing-crystal": {},
            "magnanimous-tuning-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Ren
    profession: Renegade
    value:
      {
        "extras": {
          "Sigil1": {},
          "Sigil2": {},
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "weaver": {},
            "berserker": {},
            "renegade": {}
          },
          "Relics": {},
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "meaty-asparagus-skewer": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": "4.4"
      }

  - name: Power Quickness Herald (Raid)
    profession: Herald
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "firebrand": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "air": {
              "amount": ""
            },
            "accuracy": {},
            "impact/night/slaying-only-3": {},
            "concentration": {}
          },
          "Enhancement": {
            "potent-lucent-oil": {},
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Quickness Herald (Fractal)
    profession: Herald
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "firebrand": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-both": {}
          },
          "Enhancement": {
            "slaying-potion": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Alac Ren (Raid)
    profession: Renegade
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "leadership": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "air": {},
            "concentration": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {},
            "holographic-super-cheese": {},
            "potent-lucent-oil": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-beef-carpaccio-with-salsa-garnish": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Quickness Herald
    profession: Herald
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "firebrand": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": ""
            }
          },
          "Sigil1": {},
          "Sigil2": {},
          "Enhancement": {
            "master-tuning-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "salsa-topped-veggie-flatbread": {}
          }
        },
        "lifestealAmount": "3.6"
      }

  - name: DPS Vindicator (Fractal)
    profession: Vindicator
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {},
          "Enhancement": {
            "slaying-potion": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {}
          }
        },
        "lifestealAmount": "4.18"
      }

  - name: DPS Vindicator (Raid)
    profession: Vindicator
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {}
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "air": {
              "amount": "3.1"
            }
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {}
          }
        },
        "lifestealAmount": "4.18"
      }

  - name: Power Alacrity Mechanist (Fractal, inaccurate)
    hidden: true
    profession: Mechanist
    value:
      {
        "extras": {
          "Runes": {
            "strength": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {},
          "Enhancement": {
            "slaying-potion": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {}
          }
        },
        "lifestealAmount": "0"
      }

  - name: Power Alacrity Mechanist (Raid, air, inaccurate)
    profession: Mechanist
    value:
      {
        "extras": {
          "Runes": {
            "strength": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {},
          "Enhancement": {
            "superior-sharpening-stone": {},
            "potent-lucent-oil": {},
            "holographic-super-cheese": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {}
          }
        },
        "lifestealAmount": "0"
      }

  - name: Power Alacrity Mechanist (Raid, concentration, inaccurate)
    profession: Mechanist
    value:
      {
        "extras": {
          "Runes": {
            "strength": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "concentration": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {},
            "potent-lucent-oil": {},
            "holographic-super-cheese": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {}
          }
        },
        "lifestealAmount": "0"
      }

  - name: Condi Mechanist
    profession: Mechanist
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "balthazar": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "baelfire": {},
            "flame-legion-2": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {
            "geomancy": {}
          },
          "Sigil2": {
            "earth": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": "3.4"
      }

  - name: DPS Scourge
    profession: Scourge
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "weaver": {},
            "berserker": {},
            "renegade": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "torment": {
              "amount": "1.85"
            }
          },
          "Enhancement": {
            "master-tuning-crystal": {}
          },
          "Nourishment": {
            "meaty-asparagus-skewer": {},
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Reaper Spear
    profession: Reaper
    value:
      {
        "extras": {
          "Runes": {
            "krait": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "earth": {
              "amount": ""
            }
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "plate-of-kimchi-pancakes": {},
            "truffle-risotto": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Reaper Scepter
    profession: Reaper
    value:
      {
        "extras": {
          "Runes": {
            "krait": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Sigil1": {},
          "Sigil2": {},
          "Enhancement": {
            "toxic-focusing-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "plate-of-kimchi-pancakes": {},
            "truffle-risotto": {}
          }
        }
      }

  - name: DPS Harbinger
    profession: Harbinger
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {},
            "demons": {},
            "malice": {}
          },
          "Sigil2": {
            "geomancy-torment-harb": {},
            "earth-torment-harb": {},
            "torment": {
              "amount": "1.85"
            }
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "weaver": {},
            "berserker": {},
            "renegade": {},
            "tormenting": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Nourishment": {
            "meaty-asparagus-skewer": {},
            "cilantro-and-cured-meat-flatbread": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Quickness Condi Harbinger
    profession: Harbinger
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {},
            "demons": {}
          },
          "Sigil2": {
            "torment": {
              "amount": "1.85"
            },
            "geomancy-torment-cwb-alac": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "tormenting": {},
            "firebrand": {}
          },
          "Relics": {
            "fractal-relic": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "salsa-topped-veggie-flatbread": {},
            "meaty-asparagus-skewer": {}
          },
          "Enhancement": {
            "master-tuning-crystal": {},
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Deadeye A/D
    profession: Deadeye
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "krait": {},
            "mad-king": {},
            "afflicted": {},
            "thorns": {}
          },
          "Relics": {
            "peitha-relic": {
              "amount": "1.9"
            }
          },
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "earth": {
              "amount": ""
            }
          },
          "Enhancement": {
            "master-tuning-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Quickness Condi Deadeye Spear
    profession: Deadeye
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "krait": {},
            "mad-king": {},
            "afflicted": {},
            "thorns": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": ""
            }
          },
          "Sigil1": {
            "bursting": {}
          },
          "Sigil2": {
            "earth": {
              "amount": ""
            }
          },
          "Enhancement": {
            "master-tuning-crystal": {}
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Deadeye (Raid)
    profession: Deadeye
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "deadeye-relic": {
              "amount": "95"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {},
            "accuracy": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {},
            "curry-butternut-squash-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Staff Daredevil
    profession: Daredevil
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {}
          },
          "Relics": {
            "thief-relic": {
              "amount": "4.86"
            }
          },
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "impact/night/slaying-only-3": {}
          },
          "Enhancement": {
            "superior-sharpening-stone": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-coq-au-vin-with-salsa": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Power Boon Daredevil
    profession: Daredevil
    value:
      {
        "extras": {
          "Runes": {
            "scholar": {},
            "dragonhunter": {},
            "golemancer": {},
            "deadeye": {},
            "eagle": {},
            "infiltration": {},
            "ranger": {},
            "pack": {},
            "fireworks": {},
            "firebrand": {}
          },
          "Relics": {},
          "Sigil1": {
            "force": {}
          },
          "Sigil2": {
            "concentration": {}
          },
          "Enhancement": {
            "holographic-super-cheese": {},
            "potent-lucent-oil": {}
          },
          "Nourishment": {
            "cilantro-lime-sous-vide-steak": {},
            "plate-of-beef-carpaccio-with-salsa-garnish": {},
            "spherified-cilantro-oyster-soup": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Daredevil
    profession: Daredevil
    value:
      {
        "extras": {
          "Sigil1": {
            "agony": {},
            "earth": {
              "amount": ""
            }
          },
          "Sigil2": {
            "doom": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "krait": {},
            "mad-king": {},
            "afflicted": {},
            "thorns": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": ""
            }
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {},
            "plate-of-kimchi-pancakes": {},
            "bowl-of-kimchi-tofu-stew": {}
          },
          "Enhancement": {
            "master-tuning-crystal": {},
            "toxic-focusing-crystal": {}
          }
        },
        "lifestealAmount": ""
      }

  - name: Condi Boon Daredevil
    profession: Daredevil
    value:
      {
        "extras": {
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "krait": {},
            "mad-king": {},
            "afflicted": {},
            "thorns": {},
            "firebrand": {}
          },
          "Relics": {},
          "Sigil1": {
            "concentration": {}
          },
          "Sigil2": {
            "malice": {}
          },
          "Enhancement": {
            "master-tuning-crystal": {}
          },
          "Nourishment": {
            "salsa-topped-veggie-flatbread": {}
          }
        }
      }

  - name: Condi Specter Spear
    profession: Specter
    value:
      {
        "extras": {
          "Sigil1": {
            "torment": {
              "amount": "1.72"
            },
            "bursting": {}
          },
          "Sigil2": {
            "bursting": {},
            "earth": {
              "amount": ""
            }
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "weaver": {},
            "berserker": {},
            "renegade": {},
            "tormenting": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": "0.39"
            }
          },
          "Nourishment": {
            "cilantro-and-cured-meat-flatbread": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {},
            "master-tuning-crystal": {}
          }
        },
        "lifestealAmount": "3.5"
      }

  - name: Condi Specter SC/D
    profession: Specter
    value:
      {
        "extras": {
          "Sigil1": {
            "torment": {
              "amount": "1.72"
            },
            "bursting": {}
          },
          "Sigil2": {
            "doom": {
              "amount": "0.89"
            },
            "bursting": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "weaver": {},
            "berserker": {},
            "renegade": {},
            "tormenting": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": "0.39"
            }
          },
          "Nourishment": {
            "bowl-of-kimchi-tofu-stew": {},
            "cilantro-and-cured-meat-flatbread": {},
            "meaty-asparagus-skewer": {},
            "plate-of-kimchi-pancakes": {}
          },
          "Enhancement": {
            "toxic-focusing-crystal": {},
            "master-tuning-crystal": {}
          }
        },
        "lifestealAmount": "3.5"
      }

  - name: Condi Barrier Specter SC/D
    profession: Specter
    value:
      {
        "extras": {
          "Sigil1": {
            "bursting": {},
            "demons": {},
            "torment": {
              "amount": "1.52"
            }
          },
          "Sigil2": {
            "doom": {
              "amount": "0.89"
            }
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "weaver": {},
            "berserker": {},
            "renegade": {},
            "tormenting": {}
          },
          "Relics": {},
          "Nourishment": {
            "bowl-of-kimchi-tofu-stew": {},
            "cilantro-and-cured-meat-flatbread": {},
            "meaty-asparagus-skewer": {}
          },
          "Enhancement": {
            "magnanimous-tuning-crystal": {}
          }
        },
        "lifestealAmount": "3.5"
      }

  - name: Alacrity Specter
    profession: Specter
    value:
      {
        "extras": {
          "Sigil1": {
            "torment": {
              "amount": "1.52"
            },
            "bursting": {},
            "concentration": {},
            "demons": {},
            "agony": {}
          },
          "Sigil2": {
            "geomancy-doom-alac-specter": {
              "amount": "0.89"
            },
            "geomancy": {
              "amount": "0.89"
            },
            "doom": {
              "amount": "0.89"
            },
            "torment": {
              "amount": "1.52"
            },
            "bursting": {},
            "concentration": {},
            "demons": {},
            "agony": {}
          },
          "Runes": {
            "trapper": {},
            "nightmare": {},
            "tempest": {},
            "lich": {},
            "renegade": {},
            "berserker": {},
            "weaver": {},
            "tormenting": {},
            "leadership": {}
          },
          "Relics": {
            "fractal-relic": {
              "amount": "0.47"
            }
          },
          "Nourishment": {
            "salsa-eggs-benedict": {},
            "meaty-asparagus-skewer": {},
            "plate-of-kimchi-pancakes": {},
            "cilantro-and-cured-meat-flatbread": {}
          },
          "Enhancement": {
            "toxic-maintenance-oil": {},
            "magnanimous-tuning-crystal": {}
          }
        },
        "lifestealAmount": "2.6"
      }

